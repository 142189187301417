label {
    display: block;
    margin-top: 10px;
  }
  
  .login-form,
  .signup-form {
    max-width: 350px;
    margin: auto;
  }
  
  .card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  }

  .profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }

  .button-3 {
    align-items: flex-start;
    background: linear-gradient(180deg, rgb(255, 161, 153) 0%, rgb(255, 195, 113) 100%);
    gap: 10px;
    padding: 12px 33px;
}

.alert {
  padding: 20px;   
  color: white;
  margin-bottom: 15px;
}

.alert-success{
  background-color: #04AA6D;
}

.alert-danger{
  background-color: #f44336;
}
