.user-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.frontend-link {  
  color: #503714; 
  width: max-content;
  border-radius: 8px;
  border: none;
  background-image: var(--cip-gradient-2);
  transition: 0.3s;
  margin: 4px;
  padding: 15px;
}
