.Page{
    box-sizing: border-box;
    width: 100%;
    min-height: -webkit-fill-available;
    /*display: flex;*/
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    padding-bottom: 124px;
    padding-top: 80px;
}

.PageContainer{
    background-color: rgb(250, 250, 250);
}
.components__Content{
    align-self: flex-start;
}

.components__Content > .components__Content{
    /*width: 1224px;*/
    align-self: flex-start;
    margin: 40px auto 0px;
}

.HeadingContainer{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-end;
}

.Heading{
    font-family: Sohne, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 64px;
    line-height: 56px;
    text-transform: uppercase;
}

.Title{
    font-family: Sohne, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 64px;
    line-height: 56px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.selectPoolContainer {
    display: block;
    position: relative;
}

.PromotionFlag{
    background: rgb(0, 52, 206);
    color: rgb(255, 255, 255);
}

.PromotionFlagPopularContainer{
    position: absolute;
    top: 0px;
    right: 0px;
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
    text-transform: uppercase;
    background: rgb(240, 242, 245);
    color: rgb(148, 154, 157);
    padding: 5px 9px;
    border-radius: 0px 8px;
}

.IconWrapper{
    display: inline-block;
    vertical-align: bottom;
    height: 12px;
    width: 12px;
}

.ContainerInner{
    border: 2px solid rgb(223, 225, 229);
    border-radius: 8px;
    padding: 0px 16px;
}

.MainContentContainer{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.ClickablePartContainer{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    flex-grow: 1;
    cursor: pointer;
}

.AvatarContainer{
    margin: 20px 12px 20px 4px;
}



.Avatar{
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 100em;
    font-family: Inter, sans-serif;
    font-weight: 500;
    line-height: 20px;
    background-color: rgb(240, 242, 245);    
    background-size: 32px 32px;
    background-position: center center;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: rgb(188, 192, 194);
    font-size: 12px;
}

.AvatarXLarge {
    width: 64px;
    height: 64px;
    font-size: 16px;
    background-size: 60px 60px;
}

.Pools{
    display: grid;
    grid-template-columns: repeat(2, minmax(0px, 1fr));
    gap: 16px;
    margin-bottom: 16px;
    width: 100%;
}

.Pools_Mobile{
    display: grid;
    grid-template-columns: repeat(1, minmax(0px, 1fr));
    gap: 16px;
    margin-bottom: 16px;
    width: 100%;
}


.PoolBody{
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    max-width: 840px;
    width: 100%;
    padding: 16px;
    margin: 0 auto;
}

.PoolCategory{
    font-family: Inter, sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
}

.PoolType{
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: rgb(93, 98, 104);
}

a, a:hover {
    color: inherit;
    text-decoration: none;
}


input.picker[type="date"] {
    position: relative;
  }
  
  input.picker[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    color: transparent;
    background: transparent;
  }