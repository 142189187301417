
@media (min-width: 576px) { 
    .components__Body{
        width: 100vw;
        margin: 0px 0px 40px;
    }

}

@media (min-width: 768px) { 

    .components__Body{
        width: 1224px;
        margin: 0px 40px 40px;
    }
}

.Section{
    margin-top: 32px;
}

.SectionItems {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.SectionItems > * {
   padding: 1rem;
}

.SectionItems_mobile {
    display: grid;
    grid-template-columns: repeat(1, auto);
    justify-content: start;
}


ol, ul {
    list-style: none;
}

.SectionItem{
    margin-bottom: 16px;
    width: calc(100% - 8px);
}

.SectionItem:hover {
    box-shadow: 0 5px 15px rgba(143, 142, 142, 0.8);
    cursor: pointer;
  }

.TileContainer{
    background: rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 3px;
    position: relative;
    transition: box-shadow 100ms ease-in-out 0s;
}

a, a:visited {
    color: inherit;
    text-decoration: none;
}

.TopContainer{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin: 0px 20px;
    padding: 20px 0px;
    border-bottom: 1px solid rgb(240, 242, 245);
}

.Container{
    display: flex;
    -webkit-box-align: start;
    align-items: start;
    min-width: 0px;
}



.SportTypeIconContainer{
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 100em;
    font-family: Inter, sans-serif;
    font-weight: 500;
    line-height: 20px;
    background-color: rgb(240, 242, 245);  
    background-size: 32px 32px;
    background-position: center center;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: rgb(188, 192, 194);
    font-size: 12px;
}


.AvatarXLarge{
    width: 64px;
    height: 64px;
    font-size: 16px;
    background-size: 60px 60px;
}

.Avatar{
    background: rgb(0, 52, 206);
    position: relative;
    flex-shrink: 0;
}


.HeadingContainer{
    margin-left: 20px;
    min-width: 0px;
}

.Title{
    font-family: Inter, sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: inline-block;
    margin-right: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
}

.MarkContainer{
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: top;
    margin-right: 4px;
    margin-top: 2px;
    margin-bottom: -2px;
}

.Category{
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: rgb(93, 98, 104);
}

.LastRow{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 2px;
}

.BottomContainer{
    display: table;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 20px 0px 20px 20px;
}

.BottomContainerSlot{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.Stat{
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: rgb(93, 98, 104);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.ActionsContainer{
    display: grid;
    grid-auto-flow: column;
    margin: 0px 8px;
    gap: 8px;
}
.jGsTAN{
    position: relative;
}
.gDfhWY {
    font-family: Inter, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border: 2px solid transparent;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    text-align: center;
    background-color: transparent;
    height: 32px;
    padding: 0px;
    width: 32px;
}

.iJNNot{
    visibility: visible;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.TitleContainer{
    overflow: hidden;
    display: flex;
}

.gtlhtB {
    position: absolute;
    min-width: 100%;
    width: max-content;
    z-index: 8995;
    top: 100%;
    right: 0px;
}

.jBtNiw {
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 10%) 0px 20px 48px, rgb(0 0 0 / 15%) 0px 0px 1px;
    border-radius: 8px;
    overflow: hidden;
}

.kRyDRo:first-child {
    margin-top: 4px;
}

.kRyDRo:not(:last-child) {
    border-bottom: 0px;
}

.gKjnkV:hover {
    color: rgb(8, 10, 13);
    background-color: rgb(240, 242, 245);
    text-decoration: none;
}

.gKjnkV:not(:last-child) {
    border-bottom: 1px solid rgb(240, 242, 245);
}

.kRyDRo {
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    min-height: 32px;
    margin: 0px 4px 4px;
    border-radius: 8px;
    padding: 4px 14px;
}
.gKjnkV, .gKjnkV:visited {
    color: rgb(93, 98, 104);
}
.gKjnkV {
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: rgb(8, 10, 13);
    padding: 8px 16px;
    min-height: 48px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
}