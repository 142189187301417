
.pool-card-event:hover {  
    background:#f1f3f5;
    border: 1px solid #c5914b;
    cursor: pointer;
 }

 .pool-card-event {  
    background:#f1f3f5;    
    border: 1px solid transparent;
 }
