.Page{
    box-sizing: border-box;
    width: 100%;
    min-height: -webkit-fill-available;
    /*display: flex;*/
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    padding-bottom: 124px;
    padding-top: 80px;
}

.PageContainer{
    background-color: rgb(250, 250, 250);
}
.components__Content{
    align-self: flex-start;
}

.components__Content > .components__Content{
    /*width: 1224px;*/
    align-self: flex-start;
    margin: 40px auto 0px;
}

.HeadingContainer{
    display: flex;
    -webkit-box-pack: justify;
   
    align-items: flex-end;
}

.Heading{
    font-family: Sohne, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 64px;
    line-height: 56px;
    text-transform: uppercase;
}

.Title{
    font-family: Sohne, Helvetica, sans-serif;
    font-weight: 400;
    /*font-size: 64px;*/
    line-height: 56px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.selectPoolContainer {
    display: block;
    position: relative;
}

.PromotionFlag{
    background: rgb(0, 52, 206);
    color: rgb(255, 255, 255);
}

.PromotionFlagPopularContainer{
    position: absolute;
    top: 0px;
    right: 0px;
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
    text-transform: uppercase;
    background: rgb(240, 242, 245);
    color: rgb(148, 154, 157);
    padding: 5px 9px;
    border-radius: 0px 8px;
}

.IconWrapper{
    display: inline-block;
    vertical-align: bottom;
    height: 12px;
    width: 12px;
}

.ContainerInner{
    border: 2px solid rgb(223, 225, 229);
    border-radius: 8px;
    padding: 0px 16px;
}

.MainContentContainer{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.ClickablePartContainer{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    flex-grow: 1;
    cursor: pointer;
}

.AvatarContainer{
    margin: 20px 12px 20px 4px;
}



.Avatar{
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 100em;
    font-family: Inter, sans-serif;
    font-weight: 500;
    line-height: 20px;
    background-color: rgb(240, 242, 245);    
    background-size: 32px 32px;
    background-position: center center;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: rgb(188, 192, 194);
    font-size: 12px;
}

.AvatarXLarge {
    width: 64px;
    height: 64px;
    font-size: 16px;
    background-size: 60px 60px;
}

.Pools{
    display: grid;
    grid-template-columns: repeat(2, minmax(0px, 1fr));
    gap: 16px;
    margin-bottom: 16px;
    width: 100%;
}

.PoolBody{
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    max-width: 840px;
    width: 100%;
    padding: 16px;
    margin: 0 auto;
}

.PoolCategory{
    font-family: Inter, sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
}

.PoolType{
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: rgb(93, 98, 104);
}

a, a:hover {
    color: inherit;
    text-decoration: none;
}

.Body{
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    /*width: 600px;*/
    margin: 40px 16px 105px;
    align-self: start;
}

.delAXO {
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: rgb(93, 98, 104);
}

.hTMtzz {
    margin-bottom: 8px;
}

.eFxANo {
    font-family: Inter, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border: 2px solid transparent;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    width: 100%;
    text-align: center;
    background-color: rgb(0, 52, 206);
    padding: 0px 22px;
    height: 48px;
}

.eFxANo, .eFxANo:visited {
    color: rgb(255, 255, 255);
}

.dzvAAR {
    padding-top: 16px;
    width: 100%;
}

.efgEZb{
    text-align: center;
    padding: 16px 0px;
}

.hUdbtJ{
    height: 100%;
    display: flex;
    -webkit-box-flex: 1;
    flex-grow: 1;
}

.ettamI{
    height: 100%;
    display: flex;
    -webkit-box-flex: 1;
    flex-grow: 1;
}


.dsDWeF{
    position: relative;
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: rgb(8, 10, 13);
    border: 0px;
    background: #E5E8E8;
    display: block;
    outline: none;
    padding: 24px 16px 8px;
    width: 50px;
    height: 100%;
    -webkit-box-flex: 1;
    flex-grow: 1;
    min-height: 88px;
    resize: none;
}

.isoFOS{
    position: absolute;
    z-index: 0;
    display: block;
    padding-left: 16px;
    cursor: text;
    transition: all 250ms ease-in-out 0s;   
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: rgb(148, 154, 157);
}

input.picker[type="date"] {
    position: relative;
  }
  
  input.picker[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    color: transparent;
    background: transparent;
  }

  .menu {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #cccccc;
  }
  
  .item {
    padding: 2px 6px;
    cursor: default;
  }
  
  .item-highlighted {
    color: white;
    background-color: #4095bf;
  }
  
  .item-header {
    background-color: #eeeeee;
    color: #454545;
    font-weight: bold;
  }

  