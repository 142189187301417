@media (min-width: 1350px) {
    .homepage .sportBar {
         margin-left: 120px;
         margin-right: 120px;
     }
 }
 
 .homepage .sportBar {
     font-family: 'Sohne', Helvetica;
     font-size: 18px;
     text-transform: uppercase;
     background-color: #ffffff;
     box-shadow: 0px 8px 80px rgb(0 0 0 / 12%);
     border-radius: 24px;
     margin-top: 95px;
     padding-top: 20px;
     padding-bottom: 20px;
     margin-bottom: 60px;
 }
 
 .homepage .sportBar  .sport {
     padding-top: 25px;
     padding-bottom: 21px;
     display: inline-block;
     text-align: center;
     color: #0A0E13;
     letter-spacing: 0.02em;
     width: 100%;
 }
 
 .homepage .sportBar>div {
     border-right: solid 1px #ededed;
     display: inline-block;
 }
 
 a {
     text-decoration: none;
 
 }
 
 .homepage .sportBar i {
     font-size: 26px;
     margin-bottom: 11px;
 }
 
 @media (min-width: 991px) {
     .row-cols-lg-8>* {
         width: 13.5%;
     }
 }

 .footer {
    position: relative;
    bottom: 50px;
    width: 100%;
}